import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IoHome } from "react-icons/io5";
import httpRequest from '../utils/newRequest';
import { FaSearch } from 'react-icons/fa';


const GlobalTimeMobil = () => {
  
const [scores, setScores] = useState([]);
const [scores2, setScores2] = useState([]);
const [scores3, setScores3] = useState([]);
const [searchName, setSearchName] = useState(''); 
  // MOBIL!!!!!!!!!
  const [activeTab, setActiveTab] = useState('matchGame');
  
  //Game 1 Fetch
useEffect(() => {
    const fetchScores = async () => {
      try {
        const response = await httpRequest.get('/game1');
        const scoresWithIndex = response.data.map((score, index) => ({
          ...score,
          originalIndex: index + 1
        }));
        setScores(scoresWithIndex);
      } catch (error) {
        console.error("Error fetching scores:", error);
      }
    };

    fetchScores();
  }, []);

    const filteredScores = scores.filter(score =>
    score.name.toLowerCase().includes(searchName.toLowerCase())
  );

  //Game 2 Fetch
  useEffect(() => {
    const fetchScores2 = async () => {
      try {
        const response = await httpRequest.get('/game2');
        const scoresWithIndex = response.data.map((score, index) => ({
          ...score,
          originalIndex: index + 1
        }));
        setScores2(scoresWithIndex);
      } catch (error) {
        console.error("Error fetching scores:", error);
      }
    };

    fetchScores2();
  }, []);

    const filteredScores2 = scores2.filter(score =>
    score.name.toLowerCase().includes(searchName.toLowerCase())
  );


    //Game 3 Fetch
  useEffect(() => {
    const fetchScores3 = async () => {
      try {
        const response = await httpRequest.get('/game3');
        const scoresWithIndex = response.data.map((score, index) => ({
          ...score,
          originalIndex: index + 1
        }));
        setScores3(scoresWithIndex);
      } catch (error) {
        console.error("Error fetching scores:", error);
      }
    };

    fetchScores3();
  }, []);

    const filteredScores3 = scores3.filter(score =>
    score.name.toLowerCase().includes(searchName.toLowerCase())
  );

  //MOBIL
    const renderContent = () => {
    switch (activeTab) {
      case 'matchGame':
        return (
          <div className='right'>
            <h2 className='txt-m'>Match Game</h2>
            <div className='name-result'>
              <div className='name-time'>
                <div>
                  {filteredScores.length === 0 ? (
                    <p>No results found...</p>
                  ) : (
                    filteredScores.map((score, index) => (
                      <div className='name-time' key={index}>
                        <div className='box-s'>
                          <p className='first-s'>{score.originalIndex}.</p>
                          <p className='name-o'>{score.name}</p>
                          <p className='first-l'>{new Date(score.time * 1000).toISOString().substr(11, 8)}</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case 'fallingGame':
        return (
          <div className='middle'>
            <h2 className='txt-m'>Falling Game</h2>
            <div className='name-result'>
              <div className='name-time'>
                <div>
                  {filteredScores2.length === 0 ? (
                    <p>No results found...</p>
                  ) : (
                    filteredScores2.map((score, index) => (
                      <div className='name-time' key={index}>
                        <div className='box-s'>
                          <p className='first-s'>{score.originalIndex}.</p>
                          <p className='name-o'>{score.name}</p>
                          <p className='first-l'>{new Date(score.time * 1000).toISOString().substr(11, 8)}</p>
                          <p>{score.score}</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case 'colorMatch':
        return (
          <div className='left'>
            <h2 className='txt-m'>Color Match </h2>
            <div className='name-result'>
              <div className='name-time'>
                <div>
                  {filteredScores3.length === 0 ? (
                    <p>No results found...</p>
                  ) : (
                    filteredScores3.map((score, index) => (
                      <div className='name-time' key={index}>
                        <div className='box-s'>
                          <p className='first-s'>{score.originalIndex}.</p>
                          <p className='name-o'>{score.name}</p>
                          <p className='first-l'>{new Date(score.time * 1000).toISOString().substr(11, 8)}</p>
                          <p>{score.score}</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
  <Wrapper>
      <div className='section'>
        <Link to="/">
          <IoHome className='icon-home'/>
        </Link>
        <h1 className='main-title'>Global Time</h1>
        <h5 className='main-title2'>Global Ranking</h5>
        <h6 className='main-title2 rank-title '>| RANK | NAME |  TIME | POINT |</h6>
        {/* Search */}
        <div className="search-container">
          <input type="text"
            placeholder="Search Name..."
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            className="search-input" />
          <FaSearch className="search-icon" />
        </div>
        <div className='tabs'>
          <button className={`btn ${activeTab === 'matchGame' ? 'active' : ''}`} onClick={() => setActiveTab('matchGame')}>Match Game</button>
          <button className={`btn ${activeTab === 'fallingGame' ? 'active' : ''}`} onClick={() => setActiveTab('fallingGame')}>Falling Game</button>
          <button className={`btn ${activeTab === 'colorMatch' ? 'active' : ''}`} onClick={() => setActiveTab('colorMatch')}>Color Match</button>
        </div>
        <div className='global-rank'>
          {renderContent()}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.section{
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color:  #181818;
  position: relative;
}
.main-title{
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
  margin-top: 2rem;
  text-align: center;
}
.main-title2{
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-weight: 200;
  color: white;
  text-align: center;
  margin-bottom: 3rem;
}
.global-rank{
  display: grid;
  justify-content: center;
  justify-items: center; 
}

.txt-m{
  margin-bottom: 2rem;
  color: #f6cd00;
}
.name-result{
  text-align: center;
  gap: 10px;
  color: white;
}
.rang{
  margin-right: 5px;
}
.icon-home{
  position: absolute;
  top: 5%;
  left: 90%;
  font-size: large;
  color: #181818;
  z-index: 900;
  font-size: 3rem;
  border-radius: 5px;
  background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
  cursor: pointer;
  border: 2px solid ;
  margin-left: 1rem;
}
.underline{
  width: 5px;
  height: 100%;
  background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
}
.underline2{
  width: 5px;
  height: 100vh;
  background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
}
.name-time{
  display: flex;
  gap: 10px;
}
.rank-title{
  margin-top: -2.5rem;
}
//search
.search-container {
  position: relative;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

.search-input {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: transparent;
  color: white;
}

.search-icon {
  color: #f6cd00;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.box-s{
  border-radius: 2px;
  color: #181818;
  font-weight: bold;
  margin: 8px 0px;
  display: flex;
  gap: 5px;
  width: 300px;
  background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
}
.first-s{
  margin-left: 5px;
}
.first-l{
  padding-right: 5px;
}
.name-o{
  min-width: 120px;
  text-align: start;
  margin-left: 5px;
}
.tabs{
  display: flex;
  justify-content: center;
  gap:0.5rem;
  margin-bottom: 1rem;
}
.btn{
    border: none;
    font-size: small;
    font-weight: bold;
    letter-spacing: 1px;
    color: white;
    padding: 10px 25px;
    border-radius: 2px;
    text-align: center;
    background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
    cursor: pointer;
}
@media screen and (max-width: 450px) {
.main-txt{
  margin-top: 5rem;
  font-size: 1.5rem;
}}
.btn{
  font-size: small;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
  padding: 7px 25px;
}
.main-title{
  margin-top: 3rem;
  font-size: 1.5rem;
}
.main-title2,.many{
  font-size: 0.75rem;
  letter-spacing: 2px;
}
.icon-home{
  position: absolute;
  top: 12%;
  left: 85%;
  font-size: large;
  color: #181818;
  font-size: 2rem;
  border-radius: 5px;
  background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
  cursor: pointer;
  border: 2px solid ;
  margin-left: 1rem;
}
`

export default GlobalTimeMobil