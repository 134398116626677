import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import httpRequest from '../utils/newRequest'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Win = ({ handler }) => {
  const [name, setName] = useState('');
  const [submitted, setSubmitted] = useState(false);

  //AXIOS
    const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Küldés a szerverre
      const response = await httpRequest.post('/game1', { name, time: lastGameTime });
      // Válasz kezelése
      console.log('Server response:', response.data);
      toast.success('Result submitted successfully!');
      setSubmitted(true); 
      setSubmitted(prevSubmitted => !prevSubmitted); 
    } catch (error) {
      console.error('Error sending score:', error);
      toast.error('Error to sending Result.');
    }
  };

  const handleSubmitResult = () => {
    setSubmitted(prevSubmitted => !prevSubmitted); 
  };


  const lastGameTime = JSON.parse(sessionStorage.getItem('lastGameTime')) || 0;
  
  function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}
  return (
    <Wrapper>
      <div className='center'>
        {!submitted ? (
          <>
            <div className='box'>
              <p className='txt'>Congratulations</p>
              <h1 className='txt2'>You Win</h1>
            </div>
            <div className='btn-container'>
              <button className='btn2' onClick={handler.clickhandler.start}>New Game</button>
              <Link to="/">
                <button className='btn2'>Menu</button>
              </Link>
              <button className='btn2'  onClick={handleSubmitResult}>Submit result</button>
            </div>
          </>
        ) : (
          <>
            <div className='box'>
              <p className='txt22'>Result Submitted</p>
              <p className='txt23'>submit your result to the global</p>
              </div>
            <div className='btn-container'>
                <form onSubmit={handleSubmit}>
                  <label className='name down'>NAME</label>
                  <br />
                  <input
                    type="text"
                    placeholder='Your Game Name'
                    className='input-type'
                    value={name}
                    maxLength={10}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <br />
                  <label className='name '>TIME</label>
                  <p className='timers'>{formatTime(lastGameTime)}</p>
                  <br/>
                  <button className='btn2 down2' >Send</button>
              </form>
                <button className='btn2' onClick={handleSubmitResult}>Go Back</button>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  width: 350px;
  height: 450px;
  z-index: 900;
  background-image: linear-gradient(to right top, #2f2f2f, #353535, #454545, #484848, #272727);
  border-radius: 5px;
.center{
  display: grid;
  justify-content: center;
  justify-items: center;
}
.btn-container{
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 10px;
}
.box{
  display: grid;
  justify-content: center;
  color: wheat;
}
.txt{
text-align: center;
margin-top: 4rem;
letter-spacing: 0.2rem;
font-weight: 200;
}
.txt22{
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 2rem;
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 5px;
}
.txt23{
font-size: 10px;
text-align: center;
letter-spacing: 0.2rem;
font-weight: 200;
margin-bottom: 2rem;
}
.btn2{
    margin-top: 1rem;
    width: 200px;
    border: none;
    font-size: large;
    font-weight: bold;
    letter-spacing: 1px;
    color: white;
    padding: 10px 25px;
    border-radius: 5px;
    text-align: center;
    background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
    cursor: pointer;
}
.txt2{
  text-align: center;
}
// NEW
.name{
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  color: white;
  text-align: center;
  outline: none;
}
.margin-top{
  margin-bottom: 20px;
}

.input-type {
  color: white;
  border: none;
  background-color: transparent;
  outline: none;
  border-bottom: 2px solid #ffd500; 
  margin-bottom: 1rem;
}
.input-type::placeholder {
  color: #969696; 
  opacity: 1; 
}
.timers{
  color: white;
  font-weight: bold;
  margin-left: 0px;
}
.or{
  color: wheat;
  font-weight: bold;
  margin-bottom: -10px;
}
@media screen and (max-width: 450px) {
.btn2{
  font-size: medium;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
  padding: 7px 25px;
}
.txt22{
  font-size: medium;
}
.name{
  margin-top:2rem;
}
.down2{
  
}
.input-type{
  margin-bottom: 2rem;
}
.timers{
  margin-top: 0.5rem;
}
}
`
export default Win