import React, { useState } from 'react'
import styled from 'styled-components'
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import cupmove1 from '../assets/cupmove1.jpg';
import cupmove2 from '../assets/cupmove2.jpg';
import cupmove3 from '../assets/cupmove3.jpg';
import cupmove4 from '../assets/cupmove4.jpg';
import fall from '../assets/fallingarrow1.jpg'
import fall2 from '../assets/fallingarrow2.jpg'
import match1 from '../assets/coloraroow1.jpg'
import match2 from '../assets/coloraroow2.jpg'
const Rule = () => {
  const images = [cupmove1, cupmove2, cupmove3, cupmove4];
  const fallimg = [fall, fall2];
  const match= [match1, match2];

  const [currentImageIndex1, setCurrentImageIndex1] = useState(0);
  const [currentImageIndex2, setCurrentImageIndex2] = useState(0);
  const [currentImageIndex3, setCurrentImageIndex3] = useState(0);

  const handlePrevClick1 = () => {
    setCurrentImageIndex1((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick1 = () => {
    setCurrentImageIndex1((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevClick2 = () => {
    setCurrentImageIndex2((prevIndex) =>
      prevIndex === 0 ? fallimg.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick2 = () => {
    setCurrentImageIndex2((prevIndex) =>
      prevIndex === fallimg.length - 1 ? 0 : prevIndex + 1
    );
  };

    const handlePrevClick3 = () => {
    setCurrentImageIndex3((prevIndex) =>
      prevIndex === 0 ? fallimg.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick3 = () => {
    setCurrentImageIndex3((prevIndex) =>
      prevIndex === fallimg.length - 1 ? 0 : prevIndex + 1
    );
  };


  return (
        <Wrapper>
    <div className='pv'>
        <h2 className='main-txt'>HOW YOU PLAY</h2>
        {/* MATCH GAME */}
        <div className='play-game1'>
          <div className='left'>
            <p className='op-txt'>1. Match Game</p>   
            <h3>There are 6 cups visible.</h3>
            <h3>Pick up a cup and move it to a different spot!</h3>
            <p> - If the cup is placed in the correct spot, you earn 1 point. </p>
            <p> - If the cup is placed in the wrong spot, nothing happens. </p>
            <p> - If you move a correctly placed cup to the wrong spot, you lose 1 point. </p>
            <h3>Your goal is to find the correct positions for all 6 cups.</h3>
            <h3 className='bottom'>If all 6 cups are correctly placed, you win!</h3>
          </div>
          <div className='right'>
              <div className="slider">
              <button className="arrow-button" onClick={handlePrevClick1}>
                <IoIosArrowBack />
              </button>
              <div className="image-wrapper">
                <img src={images[currentImageIndex1]} className="img-size" alt="" />
              </div>
              <button className="arrow-button" onClick={handleNextClick1}>
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        </div>   
        {/* MATCH GAME END*/}
        {/* Falling Start */}
        <div className='underline'></div>
        <div className='play-game1'>
          <div className='left'>
            <p className='op-txt'>2. Falling Cup</p>   
            <h3>Click on the falling cups to prevent them from hitting the ground!</h3>
            <h3>to prevent them from hitting the ground!</h3>
            <p> The falling cups will gradually speed up over time.  </p>
            <p> You have 3 lives, so try to catch as many cups as you can! </p>
          </div>
          <div className='right'>
              <div className="slider">
              <button className="arrow-button" onClick={handlePrevClick2}>
                <IoIosArrowBack />
              </button>
              <div className="image-wrapper">
                <img src={fallimg[currentImageIndex2]} className="img-size" alt="" />
              </div>
              <button className="arrow-button" onClick={handleNextClick2}>
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        </div>   
        {/* Falling END */}
        {/*  COLOR MATCH */}
        <div className='underline'></div>
        <div className='play-game1'>
          <div className='left'>
            <p className='op-txt'>3. Color Match</p>   
            <h3>Click on the falling cups to prevent them from hitting the ground!</h3>
            <h3>to prevent them from hitting the ground!</h3>
            <p> The falling cups will gradually speed up over time.  </p>
            <p> You have 3 lives, so try to catch as many cups as you can! </p>
          </div>
          <div className='right'>
              <div className="slider">
              <button className="arrow-button" onClick={handlePrevClick3}>
                <IoIosArrowBack />
              </button>
              <div className="image-wrapper">
                <img src={match[currentImageIndex3]} className="img-size" alt="" />
              </div>
              <button className="arrow-button" onClick={handleNextClick3}>
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        </div>   
        {/*  COLOR MATCH END*/}
        {/* Global Time */}
        <div className='underline'></div>
        <div className='play-game2'>
          <p className='op-txt'>Global Time</p>  
          <h3>This section shows the results achieved by players.</h3>
          <h3>The best result or the highest score counts.</h3>
          <h3>Try to compete with other players!</h3>
          <h3>Results from around the world can be submitted.</h3>
        </div>
      </div>
      </Wrapper>
  )
}

const Wrapper = styled.div`
height: 100%;
.play-game2{
  margin-left: 3rem;
}
.pv{
  height: 100%;
  background-color:  #181818;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  color: white;
  padding-bottom: 5rem;
}
.pv h2{
  color: #ffb300;
}
.pv h3{
  color: #ffb300;
  font-size: medium;
  margin-top: 2rem;
}
.main-txt{
  margin-bottom: 4rem;
  text-align: center;
}
.op-txt{
  color: #ffb300;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 2.2rem;

}
.underline{
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  height: 5px;
  background-color: #ffb300;
}
.play-game1 {
  display: flex;
  justify-content: center; /* Center the sections */
  align-items: flex-start; /* Align items at the top */
  margin-top: 2rem;
  gap: 5rem; /* Add a gap between the left and right sections */
}

.left, .right {
  flex: 0 1 45%; /* Ensure both sides take equal width but not full width */
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider {
  display: flex;
  align-items: center;
  margin-top: 20px; /* Add some space above the slider */
}

.arrow-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #ffb300; /* Match the color with the theme */
}

.arrow-button:hover {
  color: #fff; /* Change to white on hover for better visibility */
}

.image-wrapper {
  border: 2px solid #ffb300;
  border-radius: 5px;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.img-size {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 450px) {
  .play-game1 {
    display: grid;
}
.img-size{
object-fit: contain;
}
}

`

export default Rule