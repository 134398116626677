import React, { useEffect, useState } from 'react'
import { MdAccessTime } from "react-icons/md";
import styled from 'styled-components'
import { GiTrophyCup } from "react-icons/gi";


const DisplayTimesCM = () => {
  const [savedTimes, setSavedTimes] = useState([]);
  const [isSectionOpen, setIsSectionOpen] = useState(false);
  const [bestTime, setBestTime] = useState(null);

  const toggleSection = () => {
    setIsSectionOpen(!isSectionOpen);
  };

  useEffect(() => {
  const times = JSON.parse(sessionStorage.getItem('colorMatch')) || [];
  setSavedTimes(times);

  const storedBestTime = localStorage.getItem('bestTimesrs');
  const bestStoredTime = storedBestTime ? parseInt(storedBestTime, 10) : null;

  if (times.length > 0) {
    const currentBestTime = Math.max(...times.map(game => game.elapsedTime));

    if (bestStoredTime === null || currentBestTime > bestStoredTime) {
      localStorage.setItem('bestTimesrs', currentBestTime.toString());
      setBestTime(currentBestTime);
    } else if (bestStoredTime !== null) {
      setBestTime(bestStoredTime);
    }
  }
}, []);

useEffect(() => {
  const storedBestTime = localStorage.getItem('bestTimers');
  if (storedBestTime) {
    setBestTime(parseInt(storedBestTime, 10));
  }
}, []);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  return (
    <Wrapper>
      <div className='timers'>
        <div className='app'>
          <MdAccessTime className='time-icon' onClick={toggleSection} />
        </div>
        <div className={`section-info ${isSectionOpen ? 'open' : ''}`}>
          <div className='save'>
            <p className='txt'>Save Time</p>
            <div className='time'>
              <div className='best'>
                BEST TIME: {bestTime !== null ? formatTime(bestTime) : 'Not Play Yet'} 
                <GiTrophyCup className='trophy' />
              </div>
              {savedTimes.map((game, index) => (
                <div className='realtime' key={index}>
                  GAME {index + 1}: {formatTime(game.elapsedTime)}
                  <p className='point'>P: {game.matchCount}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.timers{
  width: 20%;
  height: auto;
  overflow: hidden;
  background-color:  transparent;
  position: absolute;
  z-index: 100;
  top: 5%;
}

.time-icon{
  font-size: 3rem;
  border-radius: 5px;
  background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
  cursor: pointer;
  border: 2px solid ;
  margin-left: 1rem;
}
.section-info{
  display: none;
  width: 100%;
  height: 100%;
  border: 3px solid;
  border-top-color:#f19100;
  border-left-color:#f6a200;
  border-right-color:#ffd500;
  border-bottom-color:#fdc400;
}
.section-info.open {
  display: block; 
}
.time{
  height: 400px;
  overflow-y: scroll;
}
.txt{
  text-align: center;
  font-size: 2rem;
  color: wheat;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-decoration: underline;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.realtime{
  display: flex;
  color: wheat;
  margin-left: 1rem;
}
.best{
  color: wheat;
  margin-left: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.trophy{
  margin-left: 10px;
}
.app{
  position: relative;
  display: flex;
}
.tooltip {
    visibility: hidden;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    margin-left: -60px; 
    opacity: 0;
    transition: opacity 0.6s;
}
.point{
  margin-left: 10px;

}
.time-icon:hover + .tooltip {
    visibility: visible;
    opacity: 1;
}
@media screen and (max-width: 550px) {
  .timers{
  width: 80%;
  overflow: hidden;
}

  .time-icon{
  margin-top: 2.3rem;
  margin-left: 3%;
  z-index: 100;
  font-size: 2.05rem;
  }
  .app{
  z-index: 500;;
  }
  .txt{
    font-size: medium;
  }
  .section-info{
    margin-top: 5px;
    width: 60%;
    background-color: black;
  }
  .time-icon:hover + .tooltip {
    display: none;
}
}


`

export default DisplayTimesCM