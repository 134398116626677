import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Cup } from "../data";
import { IoHome } from "react-icons/io5";
import { Link } from "react-router-dom";
import DisplayTimesFC from "./DisplayTimesFC";
import WinFC from "./WinFC";

//Local Constants
const settings = {
   fallingTimeout: 2500,
   speed: 5,
};

const FallingCup = () => {
   const [cups, setCups] = useState([...Cup]);
   const [visibleCups, setVisibleCups] = useState([]);
   const [cupPositions, setCupPositions] = useState([]);
   const [allCupsFallen, setAllCupsFallen] = useState(false);
   const [matchCount, setMatchCount] = useState(0);
   const [clickedCups, setClickedCups] = useState({});
   const [lives, setLives] = useState(3);
   const [speed, setSpeed] = useState(settings.speed);
   //timers
   const timersRef = useRef([]);
   const [cupfallingTimeout, setCupfallingTimeout] = useState(settings.fallingTimeout);
   const [elapsedTime, setElapsedTime] = useState(0);
   // Game Over
   const [gameOver, setGameOver] = useState(false);
   const [continuationUsed, setContinuationUsed] = useState(false);

   // BASIC
   const formatTime = time => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
   };

   const updateElapsedTime = (elapsedTime, matchCount) => {
      const games = JSON.parse(sessionStorage.getItem("colorFalling")) || [];
      games.push({ elapsedTime, matchCount });
      sessionStorage.setItem("colorFalling", JSON.stringify(games));
      sessionStorage.setItem("lastGameTimeColorFalling", JSON.stringify({ elapsedTime, matchCount }));
   };

   const checkEveryCupsFallen = () => cups.every(cup => cup.fallen);

   const handleStates = {
      cups: {
         reset: () => {
            setCups(cups => {
               const newCups = [...cups];
               newCups.forEach(cup => (cup.fallen = false));
               return newCups;
            });
         },
         fallenTrueByIndex: index => {
            setCups(cups => {
               const newCups = [...cups];
               if (newCups[index]) {
                  newCups[index] = { ...newCups[index], fallen: true };
               }

               return newCups;
            });
         },
      },
      cupPositions: {
         reset: () => {
            const newCupPositions = cups.map(() => {
               const randomLeft = Math.random() * 90;
               return {
                  left: `${randomLeft}%`,
               };
            });
            setCupPositions(newCupPositions);
         },
      },
      lives: {
         reset: () => setLives(3),
         one: () => setLives(1),
      },
      speed: {
         reset: () => setSpeed(settings.speed),
         increase: () => setSpeed(speed => speed / 1.2),
      },
      fallingTimeout: {
         reset: () => setCupfallingTimeout(settings.fallingTimeout),
      },
   };

   const handleTimers = {
      create: () => {
         handleTimers.remove();
         if (timersRef.current?.length === 0) {
            timersRef.current = cups.map((cup, index) => {
               return setTimeout(() => {
                  if (!cup.fallen) {
                     setVisibleCups(prev => [...prev, cup]);
                  }
               }, index * cupfallingTimeout);
            });
         }
      },
      remove: () => {
         timersRef.current.forEach(clearTimeout);
         timersRef.current = [];
      },
   };

   const events = {
      init: () => {
         handleStates.cupPositions.reset();
         handleTimers.create();
      },
      allCupFallen: () => {
         handleStates.cups.reset();
         setVisibleCups([]);
         setAllCupsFallen(false);
         setClickedCups({});
         handleTimers.remove();
         handleStates.speed.increase();
         handleStates.cupPositions.reset();
         setTimeout(() => {
            handleTimers.create();
         }, cupfallingTimeout);
      },
      animationEnd: index => {
         handleStates.cups.fallenTrueByIndex(index);
         setLives(prevLives => {
            const newLives = prevLives - 1;
            if (newLives <= 0) {
               events.gameOver();
            }
            return newLives;
         });
      },
      gameOver: () => {
         setGameOver(true);
         setAllCupsFallen(false);
         updateElapsedTime(elapsedTime, matchCount);
         handleTimers.remove();
         handleStates.cups.reset();
         handleStates.speed.reset();
         handleStates.fallingTimeout.reset();
      },
   };

   const actions = {
      init: () => {
         setVisibleCups([]);
         setClickedCups({});
         setGameOver(false);

         handleStates.cupPositions.reset();
         handleTimers.create();
      },
      cupClicked: cupId => {
         if (!clickedCups[cupId]) {
            setMatchCount(prev => prev + 1);
            setClickedCups(prev => ({
               ...prev,
               [cupId]: true,
            }));
            handleStates.cups.fallenTrueByIndex(cupId - 1);
         }
      },
      resetGame: () => {
         handleStates.lives.reset();
         setMatchCount(0);
         setElapsedTime(0);
         actions.init();
      },
      continueGame: () => {
         handleStates.lives.one();
         setContinuationUsed(true);
         actions.init();
      },
   };

   useEffect(() => {
      const interval = setInterval(() => {
         setElapsedTime(prevTime => prevTime + 1);
      }, 1000);

      events.init();

      return () => {
         clearInterval(interval);
         handleTimers.remove();
      };
   }, []);

   useEffect(() => {
      if (cups) {
         if (checkEveryCupsFallen()) {
            setAllCupsFallen(true);
         }
      }
   }, [cups]);

   useEffect(() => {
      if (allCupsFallen) {
         events.allCupFallen();
      }
   }, [allCupsFallen]);

   return (
      <Wrapper speed={speed}>
         <DisplayTimesFC />
         <div className="container">
            <Link to="/">
               <IoHome className="icon-home" />
            </Link>
            <div className="section">
               <h1 className="main-title">Falling Cup </h1>
               <h5 className="main-title2">Catch falling cups</h5>
               {/* MATCH COUNTER */}
               <div className="match">
                  <p className="many">How many cups much?</p>
                  <p className="matchs">{matchCount}</p>
               </div>
               <div className="timer">
                  <h4 className="timers-m">Time</h4>
                  <h5 className="timers">{formatTime(elapsedTime)}</h5>
               </div>
               <div className="underline"></div>

               <div>
                  {!gameOver ? (
                     <div className="middle" style={{ position: "relative", width: "100%", height: "100vh" }}>
                        <div className="life">
                           <img
                              src={require("../assets/cupgreen.png")}
                              id="1"
                              className="life-img"
                              alt=""
                              style={{ visibility: lives < 3 ? "hidden" : "visible" }}
                           />
                           <img
                              src={require("../assets/cupyellow.png")}
                              id="2"
                              className="life-img"
                              alt=""
                              style={{ visibility: lives < 2 ? "hidden" : "visible" }}
                           />
                           <img
                              src={require("../assets/cupred.png")}
                              id="3"
                              className="life-img"
                              alt=""
                              style={{ visibility: lives < 1 ? "hidden" : "visible" }}
                           />
                        </div>
                        {visibleCups.map((item, index) => (
                           <div
                              key={item.id}
                              className={`falling-cup ${clickedCups[item.id] ? "hidden" : ""}`}
                              style={cupPositions[index]}
                              onClick={() => actions.cupClicked(item.id)}
                              onAnimationEnd={() => events.animationEnd(index)}
                           >
                              <img src={item.img} className="img-size" alt="" />
                           </div>
                        ))}
                     </div>
                  ) : (
                     <div className="position-win">
                        <WinFC
                           onNewGame={actions.resetGame}
                           onContinueGame={actions.continueGame}
                           showContinuation={!continuationUsed}
                        />
                     </div>
                  )}
               </div>
            </div>
         </div>
      </Wrapper>
   );
};

const Wrapper = styled.div`
   .section {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      background-color: #181818;
      position: relative;
   }
   .main-title {
      text-transform: uppercase;
      font-weight: bold;
      color: white;
      margin-bottom: 1rem;
      margin-top: 2rem;
      text-align: center;
   }
   .main-title2 {
      letter-spacing: 0.2rem;
      font-weight: 200;
      color: white;
      text-align: center;
      margin-bottom: 1rem;
   }
   .left {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
   }
   .img-size {
      object-fit: contain;
      width: 100px;
      height: 125px;
      cursor: pointer;
   }
   .middle {
      display: flex;
      gap: 10rem;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
   }
   .view {
      display: flex;
      flex-wrap: 1;
   }
   .match,
   .timer {
      display: flex;
      justify-content: center;
      color: white;
      font-size: 1.5rem;
      font-weight: bold;
   }
   .matchs {
      margin-left: 1rem;
   }
   .timers {
      margin-left: 1rem;
      margin-top: 5px;
   }
   .icon-home {
      position: absolute;
      top: 5%;
      left: 90%;
      font-size: large;
      color: #181818;
      z-index: 900;
      font-size: 3rem;
      border-radius: 5px;
      background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
      cursor: pointer;
      border: 2px solid;
      margin-left: 1rem;
   }
   //NEW
   .underline {
      width: 100%;
      height: 5px;
      background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
   }

   @keyframes fall {
      0% {
         top: -100px;
      }
      100% {
         top: 80vh;
      }
   }

   .falling-cup {
      position: absolute;
      animation: ${({ speed }) => `fall ${speed}s linear forwards`}; /* Adjust duration as needed */
   }
   .position-win {
      position: absolute;
      top: 20%;
      left: 38%;
   }
   //Life
   .life {
      position: absolute;
      top: 60%;
      left: 85%;
      margin-top: -2px;
      margin-left: 2rem;
   }
   .life-img {
      margin-left: 10px;
      width: 40px;
      height: 30px;
      object-fit: contain;
   }
   .hidden {
      display: none;
   }
   @media screen and (max-width: 650px) {
      .life {
      position: absolute;
      top: 60%;
      left:30%;
   }
   }
   @media screen and (max-width: 450px) {
      .img-size {
         object-fit: contain;
         width: 60px;
         height: 85px;
         cursor: pointer;
      }
      .main-title {
         margin-top: 5rem;
         font-size: 1.5rem;
      }
      .main-title2,
      .many {
         font-size: 0.75rem;
         letter-spacing: 2px;
      }
      .matchs {
         font-size: 0.75rem;
      }
      .position-win {
         position: absolute;
         top: 20%;
         left: 3.5%;
      }
      .icon-home{
      position: absolute;
      top: 12%;
      left: 80%;
      font-size: large;
      color: #181818;
      font-size: 2rem;
      border-radius: 5px;
      background-image: linear-gradient(to right top,
         #f19100, 
         #f6a200, 
         #f6a200, 
         #fdc400,
         #ffd500);
      cursor: pointer;
      border: 2px solid ;
      margin-left: 1rem;
      }
      .life {
      position: absolute;
      top: 60%;
      left:60%;
   }
   .life-img {
      margin-left: 5px;
      width: 25px;
      height: 25px;
      object-fit: contain;
   }
   .timers-m{
      margin-top:6px;
      font-size:small;
   }
   }
`;
export default FallingCup;
