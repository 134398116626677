const Cup = [
   {
      id: 1,
      color: "blue",
      img: require("./assets/cupblue.png"),
      transferred: false,
      fallen: false,
   },
   {
      id: 2,
      color: "red",
      img: require("./assets/cupred.png"),
      transferred: false,
      fallen: false,
   },
   {
      id: 3,
      color: "green",
      img: require("./assets/cupgreen.png"),
      transferred: false,
      fallen: false,
   },
   {
      id: 4,
      color: "yellow",
      img: require("./assets/cupyellow.png"),
      transferred: false,
      fallen: false,
   },
   {
      id: 5,
      color: "pink",
      img: require("./assets/cuppink.png"),
      transferred: false,
      fallen: false,
   },
   {
      id: 6,
      color: "black",
      img: require("./assets/cupblack.png"),
      transferred: false,
      fallen: false,
   },
];

const Cup2 = [
   {
      id: 1,
      color: "blue",
      img: require("./assets/cupblack.png"),
      show: require("./assets/cupblue.png"),
   },
   {
      id: 2,
      color: "red",
      img: require("./assets/cupblack.png"),
      show: require("./assets/cupred.png"),
   },
   {
      id: 3,
      color: "green",
      img: require("./assets/cupblack.png"),
      show: require("./assets/cupgreen.png"),
   },
   {
      id: 4,
      color: "yellow",
      img: require("./assets/cupblack.png"),
      show: require("./assets/cupyellow.png"),
   },
   {
      id: 5,
      color: "pink",
      img: require("./assets/cupblack.png"),
      show: require("./assets/cuppink.png"),
   },
   {
      id: 6,
      color: "black",
      img: require("./assets/cupblack.png"),
      show: require("./assets/cupblack-d.png"),
   },
];

export { Cup, Cup2 };
