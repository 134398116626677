import React from 'react'
import styled from 'styled-components'

const CookiePolicy = () => {
  return (
    <Wrapper>
      <div className='pv'>
      <h2>Cookie Policy</h2>

<h3>1. What is a cookie?</h3>
<p>A cookie is a small text file that a website stores on your computer or mobile device when you visit the site. Cookies help the website recognize your device on subsequent visits and improve your user experience.</p>

<h3>2. What types of cookies do we use?</h3>
<ul>
  <li><strong>Essential cookies:</strong> These cookies are essential for the proper functioning of the website. For example, they enable you to log into your account or remember the contents of your shopping cart.</li>
  <li><strong>Performance cookies:</strong> These cookies collect information about how visitors use the website, such as which pages are visited most frequently and if they receive error messages from web pages.</li>
  <li><strong>Functional cookies:</strong> These cookies allow the website to remember your choices (such as your username, language, or region) and provide enhanced, more personal features.</li>
  <li><strong>Targeting cookies:</strong> These cookies track your browsing habits to deliver relevant advertisements to you.</li>
</ul>

<h3>3. How do we use cookies?</h3>
<ul>
  <li><strong>To improve user experience:</strong> Cookies help remember your settings and previous activities, providing a better user experience.</li>
  <li><strong>For analytical purposes:</strong> We use cookies to analyze the performance of our websites to understand how visitors use them and how we can improve them.</li>
  <li><strong>For advertising:</strong> We use cookies to deliver personalized advertisements to you based on your interests.</li>
</ul>

<h3>4. How can you manage cookies?</h3>
<p>You can choose to reject cookies by changing your browser settings. Please note that disabling cookies may affect the functionality of the website, and some features may not be available.</p>

<h3>5. Contact Us</h3>
<p>If you have any questions about our use of cookies, please contact us:</p>
<ul>
  <li>Email: [zrsoftwaredevelopment24@gmail.com]</li>
        </ul>
          </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
.pv{
  height: 100%;
  background-color:  #181818;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  color: white;
  padding-bottom: 5rem;
}
.pv h2{
  color: #ffb300;
}
.pv h3{
  color: #ffb300;
  font-size: medium;
  margin-top: 2rem;
}
`

export default CookiePolicy