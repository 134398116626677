import React from 'react'
import styled from 'styled-components'

const PrivacyPolicy = () => {
  return (
    <Wrapper>
    <div className='pv'>
      <h2>Privacy Policy</h2>

      <h3>1. Website Name and URL:</h3>
      <p>Website Name: Cup Games</p>
      <p>URL: <a href="http://www.cup-game.com">www.cup-game.com</a></p>

      <h3>2. Website Operator Name and Contact Information:</h3>
        <p>Name: Bata  Zoltan</p>
        <p>Name: Balint Roland</p>
      <p>Email: [zrsoftwaredevelopment24@gmail.com]</p>

      <h3>3. Personal Data Collected:</h3>
      <ul>
        <li>Name or nickname</li>
        <li>Game scores</li>
      </ul>

      <h3>4. Purpose of Data Collection:</h3>
      <ul>
        <li>Submission and storage of game scores</li>
        <li>Creation of global leaderboards</li>
      </ul>

      <h3>5. Methods of Data Collection:</h3>
      <ul>
        <li>Score submission at the end of the game</li>
      </ul>

      <h3>6. Data Sharing:</h3>
      <ul>
        <li>The collected data will not be shared with any third parties under any circumstances.</li>
        <li>The data is used exclusively for internal purposes and for displaying on global leaderboards.</li>
      </ul>

      <h3>7. Data Protection:</h3>
      <ul>
        <li>Data is encrypted using SSL/TLS technology</li>
        <li>Access to personal data is restricted</li>
        <li>Regular security checks are performed</li>
      </ul>

      <h3>8. Data Retention Period:</h3>
      <ul>
        <li>The submitted data is retained for as long as necessary to fulfill the purposes mentioned above.</li>
      </ul>

      <h3>9. Contact Information:</h3>
      <p>If you have any questions about data handling, or if you would like to request the modification or deletion of your personal data, please contact us at:</p>
        <ul>
          <li>Contact us</li>
          <li>Email: [zrsoftwaredevelopment24@gmail.com ]</li>
          <li>answer for Bata Zoltan or Balint Roland</li>
      </ul>

      <h3>10. Additional Information:</h3>
      <p>This privacy policy may be updated from time to time. Changes will be posted here, and the updated policy will take effect immediately.</p>
      </div>
      </Wrapper>
  )
}

const Wrapper = styled.div`
.pv{
  height: 100%;
  background-color:  #181818;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  color: white;
  padding-bottom: 5rem;
}
.pv h2{
  color: #ffb300;
}
.pv h3{
  color: #ffb300;
  font-size: medium;
  margin-top: 2rem;
}
`

export default PrivacyPolicy