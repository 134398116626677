import { useState, useEffect,  } from 'react'
import styled from 'styled-components';
import {CupM,CupM2 }from '../data2';
import { IoHome } from "react-icons/io5";
import { Link } from 'react-router-dom';
import DisplayTimesCM from './DisplayTimesCM';
import WinCM from './WinCM';
import { FaChevronLeft } from "react-icons/fa6";

const ColorMatch = () => {
  const [cup, setCup] = useState(CupM); 
  const [cuptwo, setCupTwo] = useState(CupM2);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [duration, setDuration] = useState(5000);
  const [matchCount, setMatchCount] = useState(0);
  const [isGameActive, setIsGameActive] = useState(true);
  const [hasClicked, setHasClicked] = useState(false);
  // Modal
  const [showWin, setShowWin] = useState(false);
  const [continuationUsed, setContinuationUsed] = useState(false);
  //Switch
  const [isSwitched, setIsSwitched] = useState(false);

    const [currentCupIndex, setCurrentCupIndex] = useState(0);
    useEffect(() => {
      if (!isGameActive) {
      updateElapsedTime(elapsedTime, matchCount); 
      setShowWin(true);
      return;
    }

    const timer = setInterval(() => {
      setElapsedTime(prevTime => prevTime + 1);
    }, 1000);

    const cupChangeInterval = setInterval(() => {
      // random cup érték
      const randomIndex = Math.floor(Math.random() * cuptwo.length);
      setCurrentCupIndex(randomIndex);
    }, duration);

    const durationInterval = setInterval(() => {
      setDuration(prevDuration => Math.max(1000, prevDuration - 300));
    }, 10000);

    return () => {
      clearInterval(timer);
      clearInterval(cupChangeInterval);
      clearInterval(durationInterval);
    };
  }, [isGameActive, duration, cuptwo.length,]);

  const handleCupPress = (clickedColor) => {
    if (!isGameActive) return;  //Nem aktív a játék, ne legyen meghívás!

    const activeCup = cuptwo[currentCupIndex]; // Az aktív pohár a jobb oldalon
    console.log("Active color:", activeCup.color, "Clicked color:", clickedColor);
    if (activeCup.color === clickedColor) {
      if (hasClicked) return;
      setMatchCount(prevCount => prevCount + 1); // Növeli a találatok számát, ha a színek megegyeznek
      setHasClicked(true); //Clcik pohára 
    } else {
      setIsGameActive(false); // Játék leállítása helytelen választás esetén
    }
  };
  

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCupIndex(prevIndex => (prevIndex + 1) % cuptwo.length);
      setHasClicked(false); 
    }, duration); // Frissítés a duration alapján

    return () => clearInterval(interval);
    
}, [cuptwo.length, duration]);
  
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

const updateElapsedTime = (elapsedTime, matchCount) => {
  const games = JSON.parse(sessionStorage.getItem('colorMatch')) || [];
  games.push({ elapsedTime, matchCount });
  sessionStorage.setItem('colorMatch', JSON.stringify(games));
  sessionStorage.setItem('lastGameTimeColorMatch', JSON.stringify({ elapsedTime, matchCount }));
};

  // NEW GAME
  const handleNewGame = () => {
    setCup(CupM);
    setCupTwo(CupM2);
    setElapsedTime(0);
    setDuration(5000);
    setMatchCount(0);
    setIsGameActive(true);
    setShowWin(false)
    resetContinuation(); 
  };
  // Continue Game 
  const handleContinueGame = () => {
    setIsGameActive(true);
    setShowWin(false);
    setContinuationUsed(true);
  };

  // Reset New Game 
  const resetContinuation = () => {
    setContinuationUsed(false);
  };

  //SwitchHand
  const handleSwitchHand = () => {
    setIsSwitched(!isSwitched);
  };

  return (
    <Wrapper>
      <DisplayTimesCM  className="icon-times"/>
      <div className='container'>
        <Link to="/">
          <IoHome className='icon-home'/>
        </Link>
        <div className='section'>
          <h1 className='main-title'>COLOR MATCH </h1>
          <h5 className='main-title2'>Click on the correct color</h5>
          <div className='middle'>
            {/* Conditionally render the left and right sections based on isSwitched */}
            {isSwitched ? (
              <>
                <div className='right'>
                  {cuptwo.map((item, index) => (
                    <div className='cup2' key={item.id}>
                      <div className='view-cup'>
                        {index === currentCupIndex && (
                          <img src={item.img} className='img-size' alt="" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className='left'>
                  {cup.map((item, index) => (
                    <div className='view' key={item.id} onClick={() => handleCupPress(item.color)}>
                      <div className='view-cup'>
                        <img src={item.img} className='img-size' alt="" />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className='left'>
                  {cup.map((item, index) => (
                    <div className='view' key={item.id} onClick={() => handleCupPress(item.color)}>
                      <div className='view-cup'>
                        <img src={item.img} className='img-size' alt="" />
                      </div>
                    </div>
                  ))}
                </div>
                <div className='right'>
                  {cuptwo.map((item, index) => (
                    <div className='cup2' key={item.id}>
                      <div className='view-cup'>
                        {index === currentCupIndex && (
                          <img src={item.img} className='img-size' alt="" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className='match'>
            <p className='many'>How many cups much?</p>
            <p className="matchs">{matchCount}</p>
          </div>
          <div className='timer'>
            <h4 className='timers-m'>Time</h4>
            <h5 className='timers'>{formatTime(elapsedTime)}</h5>
              {/* Talán jó ide */}
                  <div className='hand'>
                    <button className='btn' onClick={handleSwitchHand}>&lt; Switch Hand &gt;</button>
                  </div>
          </div>
        </div>
      </div>
      <div className={showWin ? 'visible' : 'hidden'}>
        <WinCM 
          onNewGame={handleNewGame} 
          onContinueGame={handleContinueGame} 
          showContinuation={!continuationUsed}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.section{
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color:  #181818;
  position: relative;
}
.main-title{
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
  margin-top: 2rem;
  text-align: center;
}
.main-title2{
  letter-spacing: 0.2rem;
  font-weight: 200;
  color: white;
  text-align: center;
  margin-bottom: 3rem;
}
.left{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.img-size{
  object-fit: contain;
  width: 100px;
  height: 125px;
  cursor: pointer;
}
.middle{
  display: flex;
  gap: 10rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.view {
  display: flex;
  flex-wrap: 1;
}
.match,.timer{
  display: flex;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.matchs{
  margin-left: 1rem;
}
.timers{
  margin-left: 1rem;
  margin-top: 9px;
}
.timers-m{
  margin-top: 5px;
}
.icon-home{
  position: absolute;
  top: 5%;
  left: 90%;
  font-size: large;
  color: #181818;
  z-index: 900;
  font-size: 3rem;
  border-radius: 5px;
  background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
  cursor: pointer;
  border: 2px solid ;
  margin-left: 1rem;
}

.hidden {
  display: none;
}
.visible {
  display: block;
  opacity: 1;
  position: fixed;  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;  
}
.hand{
  margin-left: 1rem;
  z-index: 10000;
}
.btn{
    border: none;
    font-size: small;
    font-weight: bold;
    letter-spacing: 1px;
    color: white;
    padding: 10px 25px;
    border-radius: 5px;
    text-align: center;
    background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
    cursor: pointer;
}
// MOBIL
@media screen and (max-width: 550px) {
  .middle{
  gap: 4rem;
}
.img-size{
  width: 60px;
  height: 85px;
}
.main-title{
  margin-top: 5rem;
  font-size: 1.5rem;
}
.main-title2,.many{
  font-size: 0.75rem;
  letter-spacing: 2px;
}
.matchs{
  font-size: 0.75rem;
}
.icon-home{
  position: absolute;
  top: 12%;
  left: 80%;
  font-size: large;
  color: #181818;
  font-size: 2rem;
  border-radius: 5px;
  background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
  cursor: pointer;
  border: 2px solid ;
  margin-left: 1rem;
}
.timers-m{
  font-size: 1rem;
}
.hand{
  position: absolute;
  margin-top: 3rem;
  margin-right: 1.5rem;
}
}
`

export default ColorMatch