import React, { useEffect, useState } from 'react'
import GlobalTime from './GlobalTime'
import GlobalTimeMobil from './GlobalTimeMobil'

const GlobalTimeView = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1200);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>
      {isMobile ? <GlobalTimeMobil /> : <GlobalTime />}
    </div>
  )
}

export default GlobalTimeView