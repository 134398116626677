import styled from 'styled-components'

const Wrapper = styled.div`
.section{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color:  #181818;
  position: relative;
}
.timers-view{
  position: absolute;
  top: 10%;
  left: 0;
  z-index: 100;
}
.middle{
  display: flex;
  justify-content: center;
}
.middle2{
  margin-top: -10.5rem;
}
.img{
  margin-top: 3rem;
  width: 200px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
  z-index: 1000;
}
.img-up {
  display: none; 
  position: absolute;
}
.z-index{
  z-index: 1000;
}
.visible {
  display: block; 
}
.btn{
    border: none;
    margin: 0 45%;
    font-size: large;
    font-weight: bold;
    letter-spacing: 1px;
    color: white;
    padding: 10px 25px;
    border-radius: 5px;
    text-align: center;
    background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
    cursor: pointer;
}
.table{
  margin-top: 1rem;
}
.table2{
  margin-top: -30rem;
}
.main-title{
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
  margin-top: 2rem;
  text-align: center;
}
.main-title2{
  letter-spacing: 0.2rem;
  font-weight: 200;
  color: white;
  text-align: center;
  margin-bottom: 3rem;
}
.point{
  margin-top:2rem;
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: white;
  gap: 11rem;
  font-size: 1.75rem;
  margin-bottom: 2rem;
}
.right{
  display: flex;
  margin-left: 15rem;
  margin-top: 1rem;
}
.left{
  display: grid;
  justify-content: center;
  align-items: center;
}

.match{
  margin-left: 1rem;
  margin-bottom: 2rem;
}
.timers{
  margin-left: 0.5rem;
}
.btn-box{
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.hidden {
  display: none;
}
.visible {
  display: block;
  opacity: 1;
  position: fixed;  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;  
}
.icon-home{
  position: absolute;
  top: 5%;
  left: 90%;
  font-size: large;
  color: #181818;
  z-index: 900;
  font-size: 3rem;
  border-radius: 5px;
  background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
  cursor: pointer;
  border: 2px solid ;
  margin-left: 1rem;
}

// Browser M
@media screen and (max-width: 900px) {
  .img{
    margin-top: 3rem;
    width: 150px;
    height: 70px;
    margin-left: -1rem;
  }
}
// MObil MADIUM
@media screen and (max-width: 550px) {
  .img{
    margin-left: 0rem;
    margin-top: 3rem;
    width: 50px;
    height: 45px;
  }
  .main-title{
    font-size: 1.5rem;
  }
  .right{
    margin-top: 2rem;
    margin-left: 6rem;
  }
  .point{
    font-size: medium;
    gap: 5px;
  }
  .timers-m{
    font-size: small;
  }
  .timers{
    font-size: small;
  }
  .left{
    display: flex;
    margin-top: 0.75rem;
  }
  .match{
    margin-left: 5px;
  }
  .btn-done{
    margin-top: -2.75rem;
  }
  .btn-start{
    margin: 0 50%;
    transform: translate(-50%, -50%);
  }
  .many{
    margin-left: -5rem;
  }
  .btn{
    width: 150px;
    margin-bottom: 1rem;
  }

  .middle{
    display: flex;
    justify-content: center;
  }
  .middle2{
    margin-top: -7rem;
  }
  .icon-home{
  position: absolute;
  top: 5.1%;
  left: 80%;
  font-size: small;
  color: #181818;
  font-size: 2rem;
  border-radius: 5px;
  background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
  cursor: pointer;
  border: 2px solid ;
  margin-left: 1rem;
}
.btn-start{
    border: none;
    margin: 0 50%;
    font-size: small;
    font-weight: bold;
    letter-spacing: 1px;
    color: white;
    padding: 8px 15px;
    border-radius: 5px;
    text-align: center;
    background-image: linear-gradient(to right top, #f19100, #f6a200, #f6a200, #fdc400, #ffd500);
    cursor: pointer;
}
.btn{
  margin: 0 50%;
  font-size: small;
  padding: 8px 15px;
}
}


`

export default Wrapper