import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
//Styles
import Wrapper from '../styles/cupsstyle';
//Controllers
import useCups from '../controllers/CupsController';
//Components
import Win from './Win';
import DisplayTimes from './DisplayTimes';
import { IoHome } from "react-icons/io5";
import { Link } from 'react-router-dom';
const Cups = () => {
  
  const {
    clickhandler,
    gameCount,
    shuffledCupData,
    shuffledCupData2,
    handleDrag,
    highlightedCupId,
    matchCounter,
    formatTime,
    elapsedTime,
    showWinModal,
    showPoint
  } = useCups();

  
  return (
    <Wrapper>
      <div className='section'>
        <div className='top-section'>
            <div className='timers-gb'>
              <DisplayTimes className='timers-gb2'/>
          </div>
          <div>
            <h3 className='main-title'>CUP Match GAME</h3>
          </div>
          <div>
            <Link to="/">
              <IoHome className='icon-home'/>
            </Link>  
            </div>
        </div>
        <div className='timers-view'>
        </div>
        <h5 className='main-title2'>Find the hidden colored cups</h5>
      <div className='cups-container'>
          <button className='btn btn-start' onClick={clickhandler.start}>{gameCount === 0 ? "Start Game" : "New Game"}</button>
          <div className='middle'>
              {/* NEW CODE */}
              <DragDropContext onDragEnd={handleDrag}>
                <Droppable droppableId="cups" direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      className="cups-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center', 
                      }}
                    >
                      {shuffledCupData.map((item, index) => (
                        <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={'cups-center ' + item.transferred}
                              style={{
                                ...provided.draggableProps.style,
                              }}
                            >
                              <img src={item.img} className="img" alt="" />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            {/* END DROP */}
          </div>
        </div>
            <div className='table'>
            <div className='middle '>
            {shuffledCupData2.map((item) => {
              return (
                <div
                  key={item.id}
                  className='cups-center2'
                  draggable="true"
                  onDragStart={(e) => handleDrag.Start(e, item.id)}
                >
                  <img src={item.show} className='img-up'
                    alt="" />
                  <img src={item.img} className="img z-index" alt="" />
                </div>
              );
            })}
          </div>
        </div>
             {/* TABLE2 HIDE */}
      { <div className='table'>
            <div className='middle  middle2'>
            {shuffledCupData2.map((item) => {
              return (
                <div
                  key={item.id}
                  className='cups-center2'
                  draggable="true"
                  onDragStart={(e) => handleDrag.Start(e, item.id)}
                  style={{ zIndex: item.id === highlightedCupId ? 100 : -100 }}
                >
                  <img src={item.show} className='img'
                    alt="" />
                </div>
              );
            })}
          </div>
        </div>}
        {showPoint && (
          <div>
            <div className='point'>
              <div className='right'>
                <p className='many'>How many cups much?</p>
                <p className="match" id="match">{matchCounter}</p>
              </div>
              <div className='left'>
                <h4 className='timers-m'>Time</h4>
                <h5 className='timers'>{formatTime(elapsedTime)}</h5>
              </div>
            </div>
            {/* HELP */}
            <div className='btn-box'>
                <button className='btn btn-done' onClick={clickhandler.help}>Help?</button>
            </div>
          </div>
          )}
      </div>
      <div className={showWinModal ? 'visible' : 'hidden'}>
        <Win handler={{ clickhandler }} />
      </div>
    </Wrapper>
  )
}


export default Cups