import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeLayout from "./page/HomeLayout";
import Cups from "./components/Cups";
import ColorMatch from "./components/ColorMatch";
import FallingCup from "./components/FallingCup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalTimeView from "./page/GlobalTimeView";
import CookieConsent from "./utils/CookieConsent";
import PrivacyPolicy from "./page/PrivacyPolicy";
import TermsofService from "./page/TermsofService";
import CookiePolicy from "./page/CookiePolicy";
import Rule from "./page/Rule";


function App() {
   return (
      <Router>
         <div className="App">
            <ToastContainer position="top-center" theme="dark" />
            <Routes>
               <Route path="/" element={<HomeLayout />} />
               <Route path="/matchcups" element={<Cups />} />
               <Route path="/fallingcup" element={<FallingCup />} />
               <Route path="/colormatch" element={<ColorMatch />} />
               <Route path="/globaltime" element={<GlobalTimeView />} />
               <Route path="/pv" element={<PrivacyPolicy />} />
               <Route path="/ts" element={<TermsofService />} />
               <Route path="/cp" element={<CookiePolicy />} />
               <Route path="/rl" element={<Rule/>} />
            </Routes>
            <div className="c-box">
               <CookieConsent/>
            </div>
         </div>
      </Router>
   );
}


export default App;
