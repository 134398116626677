import React from 'react'
import styled from 'styled-components'

const TermsofService = () => {
  return (
      <Wrapper>
    <div className='pv'>
  <h2>Terms of Service</h2>

    <h3>1. Acceptance of Terms</h3>
    <p>By accessing and using the website <a href="http://www.cup-game.com">www.cup-game.com</a> ("Website"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use the Website.</p>

    <h3>2. Use of the Website</h3>
    <ul>
      <li>You agree to use the Website only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use of the Website.</li>
      <li>You must not use the Website in any way that causes, or may cause, damage to the Website or impairment of the availability or accessibility of the Website.</li>
    </ul>

    <h3>3. User Accounts and Registration</h3>
    <ul>
      <li>The Website does not require user registration. However, you may submit your game scores and a nickname.</li>
      <li>You are responsible for ensuring that any information you provide is accurate, complete, and up-to-date.</li>
    </ul>

    <h3>4. User Conduct</h3>
    <ul>
      <li>You agree not to use the Website to upload, post, email, transmit, or otherwise make available any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.</li>
      <li>You agree not to impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>
    </ul>

    <h3>5. Intellectual Property</h3>
    <ul>
      <li>All content on the Website, including text, graphics, logos, icons, images, audio clips, and software, is the property of Cup Games or its content suppliers and is protected by international copyright laws.</li>
      <li>You may not reproduce, duplicate, copy, sell, resell, visit, or otherwise exploit the Website or any portion thereof for any commercial purpose without the express written consent of Cup Games.</li>
    </ul>

    <h3>6. Limitation of Liability</h3>
    <p>The Website is provided on an "as is" and "as available" basis. Cup Games makes no representations or warranties of any kind, express or implied, as to the operation of the Website or the information, content, materials, or products included on the Website.</p>
    <p>To the full extent permissible by applicable law, Cup Games disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. Cup Games will not be liable for any damages of any kind arising from the use of the Website, including, but not limited to, direct, indirect, incidental, punitive, and consequential damages.</p>

    <h3>7. Indemnification</h3>
    <p>You agree to indemnify and hold harmless Cup Games and its affiliates, officers, agents, and employees from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Website, your violation of these Terms, or your violation of any rights of another.</p>

    <h3>8. Changes to the Terms</h3>
    <p>Cup Games reserves the right to modify these Terms at any time. Any changes will be posted on this page, and your continued use of the Website after such changes have been made will constitute your acceptance of the new Terms.</p>

    <h3>9. Governing Law</h3>
    <p>These Terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles. Any disputes arising out of or relating to these Terms shall be resolved in the courts of [Your Country/State].</p>

    <h3>10. Contact Information</h3>
    <p>If you have any questions about these Terms, please contact us at:</p>
    <ul>
      <li>Email: [zrsoftwaredevelopment24@gmail.com]</li>
</ul>
      </div>
      </Wrapper>
  )
}

const Wrapper = styled.div`
.pv{
  height: 100%;
  background-color:  #181818;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  color: white;
  padding-bottom: 5rem;
}
.pv h2{
  color: #ffb300;
}
.pv h3{
  color: #ffb300;
  font-size: medium;
  margin-top: 2rem;
}
`

export default TermsofService