import { useState, useEffect } from 'react'

//Datas
import {Cup,Cup2 }from '../data';

function useCups() {
  const [showPoint, setShowPoint] = useState(false);
  const [gameCount, setGameCount] = useState(0);
  const [shuffledCupData, setShuffledCupData] = useState([]);
  const [shuffledCupData2, setShuffledCupData2] = useState([]);
  const [matchCounter, setMatchCountrer] = useState(0)
  const [showWinModal, setShowWinModal] = useState(false);
  // Hide CUP
  const [highlightedCupId, setHighlightedCupId] = useState(null);
  //Timer
  const [isRunning, setIsRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  //Save Time

  const init = () =>{
    setShuffledCupData(cupRandomShortGenerator(Cup));
    setShuffledCupData2(cupRandomShortGenerator(Cup2));
    setGameCount(prev => prev + 1)
    setShowWinModal(false);
  }

  function cupRandomShortGenerator(Cups){
    return Cups.sort(() => Math.random() - 0.5).slice(0, 6)
  };
  
  const clickhandler = {
    start: () => {
      init();
      setIsRunning(true);
      setElapsedTime(0);
      setHighlightedCupId(true)
      setShowPoint(true);
    },
    help: ()=>{
      if (shuffledCupData2.length > 0) {
        const randomIndex = Math.floor(Math.random() * shuffledCupData2.length);
        setHighlightedCupId(shuffledCupData2[randomIndex].id);
      }
    }
  };

  const reorderCups = ({targetPlaceIndex, draggedCupIndex, draggedCupId}) => {
    const newCups = [...shuffledCupData];
    newCups.map(cup => cup.transferred = cup.id === draggedCupId ? true : cup.transferred);
    [newCups[targetPlaceIndex], newCups[draggedCupIndex]] = [newCups[draggedCupIndex], newCups[targetPlaceIndex]];
    setShuffledCupData(newCups);
    if (matches(newCups, shuffledCupData2) === 6) {
      setShowWinModal(true);
    }
  };

  const matches = () =>{
    let realMatches = 0;
    let transferredMatches = 0;
    for (let i = 0; i < shuffledCupData.length; i++) {
      if (shuffledCupData[i].id === shuffledCupData2[i].id) {
        realMatches += 1;
      }
      if (shuffledCupData[i].id === shuffledCupData2[i].id && shuffledCupData[i].transferred === true) {
        transferredMatches += 1;
      }
    }
    const score = realMatches === 6 ? 6 : transferredMatches;
    return score;
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const updateElapsedTime = (elapsedTime)=>{
    const times = JSON.parse(sessionStorage.getItem('gameTimes')) || [];
    times.push(elapsedTime);
    sessionStorage.setItem('gameTimes', JSON.stringify(times));
    //Last Time
    sessionStorage.setItem('lastGameTime', JSON.stringify(elapsedTime));
  }
  
  // NEW DRAG UPDATE

  const handleDrag = (result) => {
    if (!result.destination) return;

    // Original Logic remains!!!!
    const draggedCupId = result.draggableId;
    const draggedCupIndex = shuffledCupData.findIndex(cup => cup.id === parseInt(draggedCupId));
    const targetPlaceIndex = result.destination.index;

    // Arrange the elements
    if (draggedCupIndex !== -1 && targetPlaceIndex !== draggedCupIndex) {
      reorderCups({ targetPlaceIndex, draggedCupIndex, draggedCupId: parseInt(draggedCupId) });
    }
  };


  
  const setShuffledCupTransferred = (bool)=>setShuffledCupData(prev=>prev.map(cup =>({ ...cup, transferred: bool})))

  useEffect(()=>{
    if(gameCount !== 0){
      setShuffledCupTransferred(false)
    }    
  }, [gameCount])

  useEffect(()=>{
    if(matchCounter === 6){
      setShuffledCupTransferred(true)
      // Modal open if you win!
      setShowWinModal(true);
      // reset time !
      setIsRunning(false);

      // Override state
      const revealedCups = shuffledCupData2.map(cup => ({
        ...cup,
        img: cup.show  // change image "show"
      }));
      setShuffledCupData2(revealedCups);
    }
  }, [matchCounter])

  useEffect(() => {
    const score = matches();
    setMatchCountrer(score);
  }, [shuffledCupData, shuffledCupData2]);
  
  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!isRunning) {
      clearInterval(interval);
    }

    if (!isRunning && elapsedTime !== 0) {
      updateElapsedTime(elapsedTime)
      setElapsedTime(0); 
    }
    return () => clearInterval(interval);
  }, [isRunning, elapsedTime]);
  // Save Time
  
  return {
  clickhandler,
  gameCount,
  shuffledCupData,
  shuffledCupData2,
  handleDrag,
  highlightedCupId,
  matchCounter,
  formatTime,
  elapsedTime,
  showWinModal,
  showPoint
  }
}



export default useCups