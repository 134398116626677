const CupM = [
  {
    id: 1,
    color: "blue",
    img : require('./assets/cupblue.png'),
    transferred: false
  },
    {
    id: 2,
    color: "red",
    img : require('./assets/cupred.png'),
    transferred: false
  },
    {
    id: 3,
    color: "green",
    img : require('./assets/cupgreen.png'),
    transferred: false
  },
    {
    id: 4,
    color: "yellow",
    img : require('./assets/cupyellow.png'),
    transferred: false
  },
    {
    id: 5,
    color: "pink",
    img : require('./assets/cuppink.png'),
    transferred: false
  },
    {
    id: 6,
    color: "black",
    img : require('./assets/cupblack.png'),
    transferred: false
  },
]

const CupM2 = [
  {
    id: 1,
    color: "blue",
    img : require('./assets/cupblue.png'),
    transferred: false
  },
    {
    id: 2,
    color: "red",
    img : require('./assets/cupred.png'),
    transferred: false
  },
    {
    id: 3,
    color: "green",
    img : require('./assets/cupgreen.png'),
    transferred: false
  },
    {
    id: 4,
    color: "yellow",
    img : require('./assets/cupyellow.png'),
    transferred: false
  },
    {
    id: 5,
    color: "pink",
    img : require('./assets/cuppink.png'),
    transferred: false
  },
    {
    id: 6,
    color: "black",
    img : require('./assets/cupblack.png'),
    transferred: false
  },
]

export {CupM, CupM2};