import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import styled from 'styled-components';
const CookieConsent = () => {
  const [show, setShow] = useState(false);
  
  useEffect(() => {
    // USer WEWBROWSER
    const userAgent = navigator.userAgent;
    // use for I_A or android....
    const isWebView = /wv|WebView|iPhone|iPad|Android/.test(userAgent);
    //CHECK .... 
    if (!isWebView) {
      const cookieConsent = Cookies.get('cookieConsent');
      if (!cookieConsent) {
        setShow(true);
      }
    }
  }, []);
  // BTN FOR Accept
  const handleAccept = () => {
    Cookies.set('cookieConsent', 'true', { expires: 365 });
    setShow(false);
  };

  if (!show) {
    return null;
  }

  return (
    <Wrapper>
      <div className='c-box'>
      <div className="cookie-consent">
        <p>We use cookies to improve your experience. By using our site, you accept our cookie policy.</p>
        <button onClick={handleAccept}>Accept</button>
      </div>

      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.c-box{
  width: 100vw;
  display: flex;
  justify-items: center;
  justify-content: center;
}

.cookie-consent {
  margin: 0 auto;
  width:60%;
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background-color: #232323;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 2rem;
}

.cookie-consent p {
  margin: 0;
}

.cookie-consent button {
  background-color: #ffbb00;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.cookie-consent button:hover {
  background-color: #0056b3;
}

`

export default CookieConsent